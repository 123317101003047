import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/Home.vue'
import BookingRequests from "@/views/BookingRequests/BookingRequests.vue";
import BookingRequestsDetails from "@/views/BookingRequests/BookingRequestsDetails.vue";
import CheckJwt from "@/views/CheckJwt.vue";
import ManageUsers from "@/components/users/ManageUsers.vue";
import ProviderScheduler from "@/components/scheduler/ProviderScheduler.vue";

const routes = [
  {
    path: '/',
    name: 'Home'
  },
  {
    path: '/BookingRequests',
    name: 'BookingRequests',
    component: BookingRequests
  },
  {
    path: '/BookingRequests/:id',
    name: 'BookingRequestsDetails',
    component: BookingRequestsDetails,
    props: true
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/About.vue')
    }
  },
  {
    path: '/CheckJwt',
    name: 'CheckJwt',
    component: CheckJwt,
    props: true
  },
  {
    path: '/ManageUsers',
    name: 'ManageUsers',
    component: ManageUsers
  },
  {
    path: '/ProviderScheduler',
    name: 'ProviderScheduler',
    component: ProviderScheduler
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
