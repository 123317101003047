<template>
  <h1>Setup Your Account</h1>
  <form v-if="user">
    <label>Account Type</label>
    <select required v-model="user.UserRole">
      <option value="Provider">Provider</option>
      <option value="Employee">Employee</option>
    </select>

    <label>First Name</label>
    <input required v-model="user.FirstName">

    <label>Last Name</label>
    <input required v-model="user.LastName">

    <label>Phone Number</label>
    <input required v-model="user.PhoneNumber">

    <label v-if="user && user.UserRole && user.UserRole === 'Provider'">Group Name</label>
    <input v-if="user && user.UserRole && user.UserRole === 'Provider'" v-model="user.GroupName">
  </form>
  
  <button @click="put(user)">Request Approval</button>
</template>

<script>
import {ref} from "vue";
import {getCurrentInstance} from "vue";
import putUser from "@/composables/users/putUser";

export default {
  setup() {
    const user = ref(getCurrentInstance().parent.proxy.myUser)
    const {error, put} = putUser()

    return {user, put}
  }

}
</script>

<style scoped>
form {
  max-width: 420px;
  margin: 30px auto;
  background: white;
  text-align: left;
  padding: 40px;
  border-radius: 10px;
}

label {
  color: #aaa;
  display: inline-block;
  margin: 25px 0 15px;
  font-size: 0.6em;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
}

input, select {
  display: block;
  padding: 10px 6px;
  width: 100%;
  box-sizing: border-box;
  border: none;
  border-bottom: 1px solid #ddd;
  color: #555;
}

button {
  font-weight: bold;
  color: #2c3e50;
  text-decoration: none;
  padding: 10px;
  border-radius: 4px;
  border-width: 0;
  background: lightblue;
  cursor: pointer;
}

</style>