﻿import axios from "axios";
import {ref} from "vue";

const getBookingRequest = (requestId) => {
    const bookingRequest = ref(null)
    const error = ref(null)

    const load = async () => {
        try {
            const url = `bookingRequests/${requestId}`;
            const response = await axios.get(url);
            bookingRequest.value = response.data;
        } catch (error) {
            console.log(error);
        }
    }

    return {bookingRequest, error, load}
}

export default getBookingRequest
