<template>
  <h1>Booking Request Details</h1>
  <p>{{bookingRequest}}</p>
  <button @click="put(bookingRequest)">Put that BR</button>
</template>

<script>
import getBookingRequest from "@/composables/bookingrequests/getBookingRequest";
import putBookingRequest from "@/composables/bookingrequests/putBookingRequest";
export default {
  setup(props) {
    const {bookingRequest, error, load} = getBookingRequest(props.id)
    const {putError, put} = putBookingRequest()
    load()
    
    return {bookingRequest, error, putError, put}
  },
  props: ['id'],
}
</script>

<style scoped>
</style>