﻿import {getIdToken} from "@/utils/AuthHelper";
import {EventModel, Model} from "@bryntum/calendar";

/*export class BookingRequestEventModel extends EventModel {
    static fields = [
        {name: 'name', type: 'string', dataSource:"bookingRequestId"},
        {name: 'location' },
        {name: 'room' },
        {name: 'notes'},
    ]
    
}*/

export class BookingRequestEventModel extends EventModel {
    static get fields() {
        return [
            { name: 'startDate' },
            { name: 'endDate' },
            { name: 'id', dataSource: 'RequestId' },
            { name: 'name' },
            { name: 'location', dataSource: '' },
            { name: 'room', alwaysWrite: true },
            { name: 'notes'},
            { name: 'resourceId'}
        ];
    }
    
    /*static fields = [
        {name: 'name', type: 'string', dataSource:"bookingRequestId"},
        {name: 'location' },
        {name: 'room' },
        {name: 'notes'},
    ]*/

}

export const useCalendarConfig = () => {
    const token = getIdToken();
    const user = "mhepburn@harding.edu"
    return {
        crudManager : {
            eventStore : {
                modelClass: BookingRequestEventModel
            },
            transport : {
                load: {
                  url: `https://loj7jysmag.execute-api.us-east-2.amazonaws.com/dev/bookingRequests/user/${user}`,
                  headers : {
                      Authorization : `Bearer ${token}`,
                      Accept: "application/json, text/plain, *!/!*"
                  },
                  method: "GET"
                },
                sync : {
                    url : `https://loj7jysmag.execute-api.us-east-2.amazonaws.com/dev/bookingRequests/user/${user}`,
                    /*mode: 'no-cors',*/
                    headers : {
                        Authorization : `Bearer ${token}`,
                        Accept: "application/json, text/plain, *!/!*"
                    },
                    method: "POST"
                }
            },
            autoSync : true,
            autoLoad: true
        },
        modes: {
            year: null,
            day: {
                hourHeight: 70,
                // These two settings decide what time span is rendered
                dayStartTime: 6,
                dayEndTime: 20,

                // Scroll to 7am initially
                visibleStartTime: 6
            },
            week: {
                hourHeight: 70,
                // These two settings decide what time span is rendered
                dayStartTime: 6,
                dayEndTime: 20,

                // Scroll to 7am initially
                visibleStartTime: 7
            }
        },
        eventEditFeature: {
            items: {
                resourceField: null,
                recurrenceCombo: null,
                editRecurrenceButton: null,
                allDayCheckBox: null,
                nameField: {
                    label: 'Procedure',
                    type: 'TextField',
                    weight: 0,
                    required: true,
                },
                locationField: {
                    label: 'Location',
                    type: 'Combo',
                    name: 'location',
                    editable: false,
                    required: true,
                    weight: 1,
                    items: {
                        ECMC: "ECMC",
                        MillardFillmore: "Millard Fillmore Suburban",
                        Sisters: "Sisters",
                        BuffaloSurgeryCenter: "Buffalo Surgery Center",
                        KenmoreMercy: "Kenmore Mercy"
                    }
                },
                roomField: {
                    label: 'Room',
                    type: 'TextField',
                    name: 'room',
                    required: true,
                    weight: 2

                },
                notesField: {
                    label: 'Notes',
                    type: 'textarea',
                    name: 'notes',
                    weight: 3
                }

            }
        }
    }
};

export const useProjectConfig = () => {
    return {
        eventStore : {
            modelClass: BookingRequestEventModel
        }
    };
};
