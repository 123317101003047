﻿import axios from "axios";
import {ref} from "vue";

const putUser = () => {
    const error = ref(null)
    const put = async (user) => {
        try {
            const url = `users`;
            console.log("Putting user:\n" + user)
            const response = await axios.put(url, user);
        } catch (error) {
            console.log(error);
        }
    }

    return {error, put}
}

export default putUser