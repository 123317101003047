<template>
  <div class="calDiv">
<!--    <div>{{bookingRequests}}</div>
    <div>{{project}}</div>-->
    <bryntum-calendar-project-model class="cal"
        ref="project"
        v-bind="projectConfig"
        :resources="resources"
        :events="events"
        
    />
    <bryntum-calendar
        ref="calendar"
        v-bind="calendarConfig"
        @datachange="change"
        :project="project"
        :resources="resources"
        :events="events"
    />
  </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue';

import {
  BryntumCalendarProjectModel,
  BryntumCalendar
} from '@bryntum/calendar-vue-3';

import {BookingRequestEventModel, useCalendarConfig, useProjectConfig} from '@/AppConfig';
import getAllUnassignedBookingRequests from "@/composables/bookingrequests/getAllUnassignedBookingRequests";

export default {
  name : 'scheduler',
  components : {
    BryntumCalendarProjectModel,
    BryntumCalendar
  },

  setup() {
    const calendar = ref(null);
    const project = ref(null);

    const calendarConfig = reactive(useCalendarConfig());
    const projectConfig = reactive(useProjectConfig());

    const {bookingRequests, error, load} = getAllUnassignedBookingRequests()
    //load()

    const resources = ref(null);
    const events = ref([]<BookingRequestEventModel>{});

    resources.value = [
      {
        id         : 1,
        name       : 'Unbooked Request',
        eventColor : 'gray'
      },
      {
        id         : 2,
        name       : 'Booked Request',
        eventColor : 'green'
      }
    ];
    events.value = []
    

    onMounted(() => {
      calendar.value.instance.value.project = project.value.instance.value;
    });

    function change({ store, action, records }) {
        console.log(`${store.id} changed. The action was: ${action}. Changed records: `, records);
        // Your sync data logic comes here
      console.log(store)
      console.log(action)
      console.log(records)
      }     
    
    return {
      project,
      calendar,
      projectConfig,
      calendarConfig,
      resources,
      events,
      bookingRequests,
      change
    };
  }
};
</script>

<style lang="scss">
@import '@/App.scss';
.calDiv {
  margin         : 0;
  display        : flex;
  flex-direction : column;
  height         : 100vh;
  font-family    : Poppins, "Open Sans", Helvetica, Arial, sans-serif;
  font-size      : 14px;
}
</style>
