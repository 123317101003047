<template >
  <authenticator>
    <Header/>
  </authenticator>
</template>

<script setup>
import {Authenticator} from "@aws-amplify/ui-vue";
  import "@aws-amplify/ui-vue/styles.css"
  
  import {Amplify} from "aws-amplify";
  import awsconfig from './aws-exports'; 
  import {ref} from "vue";
  import Header from "@/components/Header.vue";

  Amplify.configure(awsconfig)
</script>

<script>
import {ref} from "vue";

export default {
  setup(){
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
