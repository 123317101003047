﻿import axios from "axios";
import {ref} from "vue";

const putBookingRequest = () => {
    const error = ref(null)
    const put = async (bookingRequest) => {
        
        try {
            const url = `bookingRequests`;
            console.log(bookingRequest)
            const response = await axios.put(url, bookingRequest);
        } catch (error) {
            console.log(error);
        }
    }

    return {error, put}
}

export default putBookingRequest