<template>

  <div v-if="users && fields">
    <b-button v-if="canApprove === true" class="btn" @click="approveUser">Approve</b-button>
    <b-button v-if="activateText" class="btn" @click="changeUserActivation">{{ activateText }}</b-button>
    
    <b-table striped hover borderless selectable select-mode='single' responsive="true" :items="users" :fields="fields"
             @row-selected="onRowSelected" @row-unselected="onRowUnselected"/>

  </div>
</template>

<script>
import getUsers from "@/composables/users/getUsers";
import {BButton, BTable} from "bootstrap-vue-next";
import {ref} from "vue";
import putUser from "@/composables/users/putUser";

export default {
  components: {BTable, BButton},
  setup() {
    const selected = ref([])
    const {users, error, load} = getUsers()
    const {putError, put} = putUser()
    const activateText = ref(null)
    const canApprove = ref(false)
    load()

    const fields = [
      {key: 'UserId', label: 'Email'},
      {key: 'FirstName', label: 'First Name'},
      {key: 'LastName', label: 'Last Name', sortable: true},
      {key: 'PhoneNumber', label: 'Phone Number'},
      {key: 'UserRole', label: 'Role', sortable: true},
      {key: 'GroupName', label: 'Group Name'},
      {key: 'IsApproved', label: 'Approved', sortable: true},
      {key: 'Active', label: 'Active', sortable: true},
    ]

    function onRowSelected(items) {
      selected.value = items;
      canApprove.value = !items.IsApproved;
      activateText.value = items.Active === true ? "Deactivate" : "Activate";
    }

    function onRowUnselected() {
      clearState()
    }
    
    function approveUser(){
      let user = selected.value
      user.IsApproved = true;
      delete user._cellVariants
      put(user)
      load()
      clearState()
    }
    
    function changeUserActivation(){
      let user = selected.value
      user.Active = !user.Active;
      delete user._cellVariants
      put(user)
      load()
      clearState()
    }
    
    function clearState(){
      selected.value = null
      canApprove.value = null
      activateText.value = null
    }

    return {users, error, fields, selected, onRowSelected, onRowUnselected, activateText, canApprove, approveUser, changeUserActivation}
  }
}
</script>

<style scoped>
.btn {
  background-color: steelblue;
  margin-right: 10px;
  margin-bottom: 5px;
}
</style>