﻿import axios from "axios";
import {ref} from "vue";

const getUsers = () => {
    const users = ref(null)
    const error = ref(null)

    const load = async () => {
        try {
            const url = `users`;
            const response = await axios.get(url);

            for (let i = 0; i < response.data.length; i++) {
                if(response.data[i].IsApproved === false)
                    response.data[i]._cellVariants = {  IsApproved: 'warning' }

                if(response.data[i].Active === false)
                    response.data[i]._cellVariants = {  Active: 'danger' }
            }
                        
            users.value = response.data;

            return response.data;
        } catch (error) {
            console.log(error);
        }
    }

    return {users, error, load}
}

export default getUsers
