<template>
  <header>
    <div class="overlay">
      <h1>UTW Associates</h1>
      <br>
    </div>
  </header>
  
  <div v-if="myUser && myUser.IsApproved && myUser.Active">
    <nav>
<!--      <router-link :to="{ name: 'Home' }">Home</router-link>-->
      
      <router-link v-if="myUser.UserRole === 'Provider'" :to="{ name: 'ProviderScheduler' }">Scheduler</router-link>
      <router-link v-if="myUser.UserRole === 'Employee'" :to="{ name: 'BookingRequests' }">Booking Requests</router-link>
      
      <router-link v-if="myUser.IsAdmin === true" :to="{ name: 'ManageUsers' }">Manage Users</router-link>
      <router-link v-if="myUser.IsAdmin === true" :to="{ name: 'CheckJwt' }">Check JWT</router-link>

      <button @click="signOut">Sign Out</button>
    </nav>
    <router-view/>
  </div>

  <div v-if="myUser && !myUser.IsApproved">
    <SetupUser/>
    <div>
      <button @click="signOut">Sign Out</button>  
    </div>
    
  </div>
  
  <div v-if="myUser && !myUser.Active">
    <h1>Your account has been deactivated.  Please reach out to an admin.</h1>
    <button @click="signOut">Sign Out</button>
  </div>
  
<!--  <nav>
    <button @click="signOut">Sign Out</button>
  </nav>-->
</template>

<script>
import {ref, toRefs} from "vue";
import getUser from "@/composables/users/getUser";
import {useAuthenticator} from "@aws-amplify/ui-vue";
import SetupUser from "@/components/users/SetupUser.vue";

export default {
  components: {SetupUser},
  setup() {
    const userid = localStorage.getItem("lastUserId")
    const {myUser, error, load, role } = getUser()
    const {route, user, signOut} = toRefs(useAuthenticator());
    load(userid)

    return {myUser, role, signOut}
  }
}
</script>

<style scoped>
nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
  text-decoration: none;
  padding: 10px;
  border-radius: 4px;
}

nav a.router-link-exact-active {
  color: white;
  background: steelblue;
}

nav button {
  font-weight: bold;
  color: #2c3e50;
  text-decoration: none;
  padding: 10px;
  border-radius: 4px;
  border-width: 0;
  background: white;
  cursor: pointer;
}

*{padding: 0; margin: 0; box-sizing: border-box;}
body{height: 900px;}
header {
  text-align: center;
  width: 100%;
  height: auto;
  background-size: cover;
  background-attachment: fixed;
  position: relative;
  overflow: hidden;
  border-radius: 0 0 85% 85% / 20%;
}

header .overlay{
  width: 100%;
  height: 100%;
  color: #FFF;
  text-shadow: 1px 1px 1px #333;
  background-image: linear-gradient( 135deg, steelblue 40%, white 100%);
}

h1 {
  font-family: 'Georgia',serif;
  font-size: 80px;
  margin-bottom: 2px;
}

button {
  font-weight: bold;
  color: #2c3e50;
  text-decoration: none;
  padding: 10px;
  margin-top: 10px;
  border-radius: 4px;
  border-width: 0;
  background: lightblue;
  cursor: pointer;
}
</style>