<template>
 <h1>Unclaimed Booking Requests:</h1>
  <div v-for="br in bookingRequests" :key ="br.requestId" class = "bookingRequest">
    <router-link :to="{name:'BookingRequestsDetails', params: {id: br.RequestId}}"><h2>{{br.Procedure}} requested by {{br.RequestedByUserId}} on {{ br.StartTime }}</h2></router-link>
  </div>
</template>

<script>
import getAllUnassignedBookingRequests from "@/composables/bookingrequests/getAllUnassignedBookingRequests";

export default {
  setup() {
    const {bookingRequests, error, load} = getAllUnassignedBookingRequests()
    load()
    
    console.log(bookingRequests)
    return {bookingRequests, error}
  },
}
</script>

<style scoped>
 .bookingRequest h2{
   background: lightblue;
   padding: 20px;
   border-radius: 10px;
   margin: 10px auto;
   max-width: 600px;
   cursor: pointer;
   color: #444;
 }
 
 .bookingRequest h2:hover {
   background: #ddd;
 }
 
 .bookingRequest a{
   text-decoration: none;
 }
 
 

</style>