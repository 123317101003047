﻿export function getLastUserId() {
    return localStorage.getItem("CognitoIdentityServiceProvider.660p60kfpn33aj3hkcjt29s7q6.LastAuthUser");
}

export function getLastUsername() {
    return localStorage.getItem(`CognitoIdentityServiceProvider.660p60kfpn33aj3hkcjt29s7q6.${getLastUserId()}.idToken`).loginId;
}

export function getIdToken() {
    return localStorage.getItem(`CognitoIdentityServiceProvider.660p60kfpn33aj3hkcjt29s7q6.${getLastUserId()}.idToken`);    
}