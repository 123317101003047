<template>
  <div>User: {{ myUsername }}</div>
  <div>Token: {{ myIdToken }}</div>
</template>

<script>
import { getCurrentUser } from 'aws-amplify/auth';
import { fetchAuthSession } from 'aws-amplify/auth';
import {onMounted, ref} from "vue";

export default {

  setup() {
    const myIdToken = ref("")
    const myUsername = ref("")

    const setCurrentSession = async () => {
      try {
        const {accessToken, idToken} = (await fetchAuthSession()).tokens ?? {};
        myIdToken.value = idToken
      } catch (err) {
        console.log(err);
      }
    }

    const setCurrentAuthenticatedUser = async () => {
      try {
        const user = await getCurrentUser();
        myUsername.value = user;
      } catch (err) {
        console.log(err);
      }
    }

    onMounted(() => {
      setCurrentSession();
      setCurrentAuthenticatedUser();
        }
    )

    return {myIdToken, myUsername, setCurrentSession, setCurrentAuthenticatedUser}
  }
}
</script>
