﻿import axios from "axios";
import {ref} from "vue";

const getAllUnassignedBookingRequests = () => {
    const bookingRequests = ref([])
    const error = ref(null)

    const load = async () => {
        try {
            const url = `bookingRequests`;
            const response = await axios.get(url);
            bookingRequests.value = response.data;
        } catch (error) {
            console.log(error);
        }
    }

    return {bookingRequests, error, load}
}

export default getAllUnassignedBookingRequests
