﻿import axios from "axios"
import {getIdToken} from "@/utils/AuthHelper";

axios.defaults.baseURL = "https://loj7jysmag.execute-api.us-east-2.amazonaws.com/dev"

axios.interceptors.request.use(config => {
    const token = getIdToken();
    config.headers["Authorization"] = `Bearer ${token}`;
    /*config.headers["origin"] = "https://www.utwassociates.com";*/
    return config;
});