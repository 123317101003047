﻿import {Hub} from "aws-amplify/utils";
import getUser from "@/composables/users/getUser";
import { Cache } from 'aws-amplify/utils';

Hub.listen('auth', ({ payload }) => {
    switch (payload.event) {
        case 'signedIn':
            console.log('user have been signedIn successfully.');
            console.log(payload);
            localStorage.setItem("lastUserId", payload.data.signInDetails.loginId)

            const {myUser, error, load, role} = getUser()
            load(payload.data.signInDetails.loginId).then(r => {localStorage.setItem('user', r.UserRole); console.log(r)})
            break;
    }})