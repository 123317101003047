﻿import axios from "axios";
import {ref} from "vue";

const getUser = () => {
    const myUser = ref(null)
    const error = ref(null)
    const role = ref("")

    const load = async (userId) => {
        try {
            const url = `users/${userId}`;
            const response = await axios.get(url);
            myUser.value = response.data;
            role.value = response.data.UserRole;
            
            return response.data;
        } catch (error) {
            console.log(error);
        }
    }

    return {myUser, error, load, role }
}

export default getUser
